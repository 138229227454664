.thumbsContainer {
	display: "flex";
	flex-direction: "row";
	flex-wrap: "wrap";
	margin-top: 16;
}

.thumb {
	display: "inline-flex";
	border-radius: 2;
	border: "1px solid #eaeaea";
	margin-bottom: 8;
	margin-right: 8;
	width: 100;
	height: 100;
	padding: 4;
}

.thumbInner {
	display: "flex";
	min-width: 0;
	overflow: "hidden";
}

.img-preview {
	height: 10rem;
}
