.logo-company {
	width: 150px;
}
.logo-company-minimize {
	width: 40px;
}
@media (max-width: 768px) {
	.logo-company {
		width: 100px;
	}
}
