.card-reports {
	height: 275px;

	.card-body {
		overflow: auto;
		padding: 0.5rem 0 !important;
	}
}

.dropzone {
	min-height: auto;
	padding: 1.5rem 1.75rem;
	text-align: center;
	cursor: pointer;
	border: 1px dashed #009ef7;
	background-color: #f1faff;
	border-radius: 0.475rem !important;

	.dz-message {
		margin: 0;
		display: flex;
		text-align: left;
	}
}

@media (max-width: 820px) {
	.imports {
		flex-direction: column;

		.card {
			margin: 1rem 0 !important;
		}
	}
	.update {
		min-width: 150px !important;
	}

	.card-reports {
		height: 250px !important;
	}
}
