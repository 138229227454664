@page {
	size: A4 portrait;
	margin: 1.5cm 1cm 1.2cm;
	counter-increment: page;
}

@media print {
	@page {
		size: landscape !important;
	}
	.width-print {
		width: 100rem !important;
	}
}
