.resume-card-icon {
	position: absolute;
	left: -10px;
	bottom: 0;
	overflow: hidden;
	width: 60px;
	height: 60px;
}

.resume-card-icon-image {
	position: absolute;
	right: 0;
	top: 0;
	width: 70px;
	height: 70px;
	transform: rotateZ(12.5deg);
	filter: grayscale(1) opacity(0.6) brightness(70);
}
