@media (max-width: 820px) {
	.options {
		flex-direction: column;
		gap: 1rem;
		width: 100%;
	}

	.container-new-professional {
		flex-direction: column;
		gap: 1rem;
		width: 100%;

		.search {
			width: 100%;

			.search-input {
				width: 100% !important;
			}
		}
	}

	.container-cpf-busy {
		flex-direction: column;
	}

	.header-professional-access {
		flex-direction: column;
	}

	.new-unit {
		align-self: center !important;
		margin-top: 1rem;
	}

	.container-buttons button {
		margin-bottom: 0.5rem;
	}
}

.alert {
	border-radius: 5px;
	padding: 0.5rem 1rem;
}

.alert-block {
	background-color: #f64e6066;
	color: #ff2b2b;
}

.alert-active {
	background-color: #a1fdcb80;
	color: #00b553;
}

.alert-pending {
	background-color: #ffc7007a;
	color: #937200;
}

.table tr {
	border-bottom: 1px rgb(190, 181, 181) solid !important;
}
