@page {
	size: A4 landscape !important;
}

@media print {
	@page {
		orientation: landscape !important;
	}
}

.button-color-dashboard {
	color: #464e5f !important;
}
