#right-image-layout {
	background-image: url("../../../../../_metronic/assets/backgrounds/auth-background.svg");
}

.container-login {
	display: flex;
	overflow: hidden;
	height: 100vh;
}

.login-form {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

@media screen and (max-width: 767px) {
	#left-image-layout {
		display: none;
	}
}

@media screen and (min-width: 768px) {
	.login-form {
		position: absolute;
		padding: 50px;
		top: 50%;
		left: 66.5%;
		transform: translate(-50%, -45%);
	}
}

@media screen and (min-width: 414px) {
	.login-form {
		position: absolute;
		top: 50%;
		transform: translate(-50%, -40%);
		width: 400px;
	}
}

@media screen and (min-width: 320px) {
	.login-form {
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 300px;
	}
}
