@media (max-width: 820px) {
	.filters-registration-management {
		gap: 1rem !important;
	}
	.container-filters-registration-management {
		flex-direction: column !important;
		align-items: start !important;

		.filters-registration-management {
			width: 100%;
			margin-top: 1rem !important;

			.input-of-buttons-filters {
				width: 100% !important;
				flex-direction: column;
				gap: 0.5rem !important;

				.input-search-filters {
					width: 100% !important;

					#citizen-name-input {
						width: 100% !important;
					}
				}

				.button-search {
					width: 7.5rem !important;
					margin: 0 auto;
				}

				.button-options {
					width: 7.5rem !important;
					margin: 0 auto;
				}
			}
		}
	}
}
