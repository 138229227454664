.indicator-quantity-icon {
	width: 15%;
}

.indicator-quantity-title {
	width: 30%;
}

.indicator-quantity-description {
	width: 55%;
}
