.loading-screen-overlay {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: rgba(4, 9, 17, 0.8);
	transition: opacity 0.4s;
	z-index: 2000;

	display: flex;

	opacity: 0;
	visibility: hidden;

	&.loading-screen-overlay--visible {
		opacity: 1;
		visibility: visible;
	}

	.loading-screen-modal {
		background: var(--bs-body-bg);
		width: min(50rem, 90%);
		margin: auto;

		user-select: none;
		border-radius: 0.8rem;
		padding: 3.5rem;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2rem;

		.loading-screen-spinner {
			width: 5rem;
			height: 5rem;
		}
	}
}
