@media screen and (max-width: 414px) {
	#login-body-logo {
		height: 50px;
	}
	.login-form-container {
		width: 100% !important;
	}
}

.units-select-container {
	width: min(100%, 600px);
	margin-right: 5%;
	margin-left: 5%;
}

#unitSelect-layout {
	background-image: url("../../../../../_metronic/assets/backgrounds/auth-background.svg");
}

#login-body-logo {
	height: 65px;
}
