.popover-body-custom {
	max-width: none;
	max-height: 200px;
	overflow-y: auto;
	overflow-x: visible;
	word-wrap: break-word;
	white-space: pre-wrap;
}

.custom-popover {
	max-width: none;
}
