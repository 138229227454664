:root {
	padding: 0px;
	margin: 0px;
}
@media print {
	.print-container {
		display: block !important;
	}

	.no-print {
		display: none !important;
	}

	.table-responsive {
		overflow-x: hidden !important;
	}
}

p {
	font-size: 0.85rem;
}

.print-container {
	display: none;
}

.no-visible {
	display: none;
	color: #fff;
}

.no-print {
	display: revert !important;
}

.page-break-before {
	page-break-before: always;
}

.white-space-nowrap {
	white-space: nowrap;
}
