input.verification-code-input {
	width: 2em;
	font-size: 2rem;
	text-align: center;
	background-color: transparent;

	border: none;
	border-bottom: 1px solid #a1a5b7;

	&:focus {
		outline: none;
	}
}

@media screen and (max-width: 1092px) {
	.verification-code-container {
		width: 70%;
	}
}

@media screen and (max-width: 767px) {
	.verification-code-container {
		width: 90%;
	}
}

@media screen and (max-width: 514px) {
	.verification-code-container {
		width: 100%;
	}

	input.verification-code-container {
		font-size: 1rem;
		width: 1.5em;
	}
}
