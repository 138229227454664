.next-button {
	background-color: #0f4395;
	color: #fff;

	border: none;
	border-radius: 3px;

	padding: 0.5rem 1.2rem;
	font-size: 1.35rem;
	font-weight: 700;

	display: flex;
	align-items: center;
	justify-content: center;

	&:disabled {
		opacity: 0.8;
		pointer-events: none;
	}

	svg {
		color: #fff;
		font-size: 1.35rem;
	}
}
